<script>
import { marked } from 'marked'

import Sheet from '../../../../jelly-sheet/src/components/view/Sheet.vue'
import SheetSettings from '../../../../jelly-sheet/src/settings/SheetSettings'

export default {
  name: 'DocSheet',
  props: { doc: String },
  data: () => ({
    sheets: [],
    template: null,
    settings: null,
    mdOptions: {
      lyric: [
        { name: 'renderer', path: 'user.renderer', value: 'lyric' },
        {
          name: 'renderOptions',
          path: 'user.renderOptions',
          value: ['lyric', 'harmony']
        }
      ],
      staff: [
        { name: 'renderer', path: 'user.renderer', value: 'staff' }
        // { name: 'spacing', path: 'user.spacing', value: 'dense' },
      ],
      chart: [
        { name: 'renderer', path: 'user.renderer', value: 'chart' },
        {
          name: 'renderOptions',
          path: 'user.renderOptions',
          value: ['lyric', 'harmony']
        }
      ]
    }
  }),
  watch: {
    doc() {
      this.update(true)
    }
  },
  mounted() {
    const sheets = this.sheets
    const mdOptions = this.mdOptions

    const renderer = {
      paragraph(text) {
        const match = text.match(/^\((.+)\)(.+)/)
        if (match) {
          const char = match[1]
          if (char === '*') {
            return `<p class="annotation">${match[2]}</p>`
          }
        }
        return `<p>${text}</p>`
      },
      code(code, infostring) {
        const infos = infostring.trim().split(/\s+/)
        if (infos[0] === 'songjelly') {
          const options = []
          const index = sheets.length
          infos.shift()
          infos.forEach((info) => {
            if (mdOptions[info]) {
              options.push(...mdOptions[info])
            }
          })
          const settings = new SheetSettings()
          settings.sheet.margin = 0
          settings.sheet.padding = 0
          // settings.staff.background.color = 0xf5f5f5
          sheets.push({ source: code, options, settings })
          return `<div class="code source">${code}</div>
          <div class="sheet-container"><sheet
            force-canvas locked
            :source="sheets[${index}].source"
            :options="sheets[${index}].options"
            :settings="sheets[${index}].settings"/></div>`
        } else {
          return `<div class="code">${code}</div>`
        }
      }
    }
    marked.use({ renderer })

    this.update()
  },
  render(a) {
    if (!this._isMounted) return
    return a({
      components: { Sheet },
      data: () => ({
        sheets: this.sheets
      }),
      template: this.template
    })
  },

  methods: {
    update() {
      const opts = { headerIds: true }
      this.template = `<div class="doc-sheet">${marked.parse(
        this.doc,
        opts
      )}</div>`
      this.$forceUpdate()
    }
  }
}
</script>
<style scoped>
.doc-sheet /deep/ .sheet-container {
  background: var(--sheet-background-color);
  border: solid var(--app-color-line) 1px;
  border-radius: 4px;
  padding: var(--app-gutter-sm);
}
.doc-sheet /deep/ .sheet {
  --sheet-margin: 0;
  --sheet-padding: 0;
}
.doc-sheet /deep/ table {
  border: 1px solid;
  margin-block-start: 1em;
  margin-bottom: var(--app-gutter-md);
}
.doc-sheet /deep/ td,
.doc-sheet /deep/ th {
  padding: 0 8px;
}

.doc-sheet /deep/ h1 {
  margin-bottom: var(--app-gutter-md);
}
.doc-sheet /deep/ h2,
.doc-sheet /deep/ h3,
.doc-sheet /deep/ p {
  margin-bottom: var(--app-gutter-md);
  margin-top: var(--app-gutter-md);
}
.doc-sheet /deep/ h2:first-child {
  margin-top: initial;
}
.doc-sheet /deep/ .annotation {
  font-size: small;
}
.doc-sheet /deep/ .code {
  font-family: monospace;
  padding: 0.4em;
  background: var(--app-color-layout);
  border-radius: 3px;
  white-space: pre;
  font-size: 13px;
  padding: var(--app-gutter-sm);
  margin-bottom: var(--app-gutter-md);
  overflow-x: auto;
}
.doc-sheet /deep/ .sheet {
  /* margin-bottom: var(--app-gutter-sm); */
}
.doc-sheet /deep/ .renderer {
  text-align: start;
  --sheet-min-width: 0;
  --sheet-min-height: 0;
  --sheet-box-shadow: initial;
  --sheet-measure-width: 150px;
  --sheet-measure-height: 80px;
}
.doc-sheet /deep/ .staff-renderer {
  /* BUG PATCH */
  line-height: 10px;
}

.doc-sheet /deep/ .chart-renderer .row {
  grid-template-columns: repeat(auto-fit, 150px) !important;
}
</style>
