<template>
  <div class="doc-bar">
    <tile-button icon="chevron-left" class="divider mr-3" to="/doc" exact />
    <span>{{ name }}</span>
  </div>
</template>

<script>
import TileButton from '../button/TileButton.vue'
export default {
  name: 'DocBar',
  components: { TileButton },
  props: { name: String }
}
</script>

<style scoped>
.doc-bar {
  display: inline-flex;
  width: 100%;
  background: var(--app-color-layout);
  border-bottom: solid 1px var(--app-color-line);
  align-items: center;
  height: 49px;
  z-index: 3;
  /* padding: 0 var(--app-gutter-md); */
}
</style>
