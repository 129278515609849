<template>
  <v-sheet :class="docClass">
    <doc-bar v-if="pageName" :name="pageName" />
    <div class="doc-content">
      <doc-sheet v-if="doc" :doc="doc" />
      <app-spinner v-else-if="loading" />
      <div class="doc-home" v-else>
        <h2>Documentation</h2>
        <h3>Score source code format</h3>
        <p>
          Songjelly songs are written with a simple text editor. You'll find
          here the documentation to understand how it works.
        </p>
        <ul>
          <li v-for="page in pageList" :key="page.index">
            <router-link :to="page.to">
              {{ page.label }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import DocSheet from '../misc/DocSheet.vue'
import DocBar from '../toolbar/DocBar.vue'
import AppSpinner from '../misc/AppSpinner.vue'

export default {
  name: 'Doc',
  components: { DocSheet, DocBar, AppSpinner },
  props: {
    docPath: String
  },

  data: () => ({
    doc: null,
    loading: false,
    pages: [
      { to: 'intro', label: 'Introduction' },
      { to: 'melody', label: 'Melody stream' },
      { to: 'lyrics', label: 'Lyrics stream' },
      { to: 'harmony', label: 'Harmony stream' }
    ]
  }),
  computed: {
    pageList() {
      return this.pages.map((page, index) => ({
        label: page.label,
        to: `/doc/${page.to}`,
        index,
        next: this.pages[index + 1]?.to || null,
        prev: this.pages[index - 1]?.to || null
      }))
    },
    pageName() {
      const docPath = this.docPath
      return this.pages.find((e) => e.to === docPath)?.label || null
    },
    docClass() {
      return {
        doc: true,
        'with-bar': Boolean(this.pageName)
      }
    }
  },
  watch: {
    docPath: {
      async handler(value) {
        this.doc = null
        if (!value) return
        this.loading = true
        fetch(`/doc/${value}.md`)
          .then(async (res) => {
            const md = await res.text()
            if (md.startsWith('<!DOCTYPE html>')) {
              throw new Error('wrong-path')
            }
            this.doc = md
          })
          .catch((error) => {
            console.log(error)
            this.doc = null
          })
          .finally(() => {
            this.loading = false
          })
      },
      immediate: true
    }
  }
}
</script>
<style scoped>
.v-sheet {
  align-items: center;
}
.doc-bar {
  position: fixed;
}
.doc-content {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: var(--app-gutter-md);
  font-size: 14px;
  min-width: 320px;
  overflow: auto;
}
.with-bar .doc-content {
  top: 46px;
}
.doc-home,
.doc-sheet {
  font-size: 14px;
  max-width: 900px;
  margin: auto;
}

.doc-content h2,
.doc-content h3 {
  margin-block-end: 1em;
}
.doc-content li {
  padding: var(--app-gutter-xs);
}
</style>
